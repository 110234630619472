import {
  ref, onMounted, getCurrentInstance, reactive
} from 'vue'
import { stickerUseCase } from '@/domain/usecase'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'

export default {
  name: 'StickerForm',
  props: {
    dataEdit: {
      default: () => null
    }
  },
  emits: ['closeSidebar', 'reloadData'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const store = useStore()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const dataEdit = ref(props.dataEdit)
    const title = ref('')
    const route = useRoute()
    const submitted = ref(false)
    const dataForm = reactive({
      code: '',
      name: '',
      isActive: true,
      description: ''
    })
    const rules = {
      code: {
        required: helpers.withMessage('Kode Stiker harus diisi', required)
      },
      name: {
        required: helpers.withMessage('Nama Stiker harus diisi', required)
      },
    }
    const v$ = useVuelidate(rules, dataForm)

    const closeSidebar = () => {
      emit('closeSidebar')
    }

    const getDataForm = async () => {
      title.value = 'Tambah Stiker'
      if (dataEdit.value) {
        dataForm.code = dataEdit.value.Kode
        dataForm.name = dataEdit.value.Nama
        dataForm.isActive = dataEdit.value.IsActive
        dataForm.description = dataEdit.value.Keterangan

        title.value = 'Edit Stiker'
      }
      // let response = await stickerUseCase.getDataForm(id.value)
      // title.value = response.title
      // if (response.data) {
      //   response = response.data
      //   if (response.error) {
      //     $toast.add({
      //       severity: 'error', detail: 'Gagal mengambil detail data, silahkan coba kembali', group: 'bc', life: 3000
      //     })
      //   } else {
      //     code.value = response.result.Kode
      //     name.value = response.result.Nama
      //     isActive.value = response.result.IsActive
      //   }
      // }
    }

    const proccessSubmit = async () => {
      store.dispatch('showLoading')
      const data = {
        Kode: dataForm.code,
        Nama: dataForm.name,
        IsActive: dataForm.isActive,
        Keterangan: dataForm.description
      }
      let id = ''
      if (dataEdit.value) {
        id = dataEdit.value.Id
      }
      const response = await stickerUseCase.submitData(id, data)
      submitted.value = false

      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorSubmit, group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      } else {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
        })
        emit('reloadData')
        closeSidebar()
      }
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      proccessSubmit()
    }

    const deleteData = () => {
      if (!dataEdit.value) {
        $toast.add({
          severity: 'error', detail: 'Gagal menghapus stiker, silahkan coba kembali', group: 'bc', life: 3000
        })
        return
      }
      $confirm.require({
        header: 'Stiker',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await stickerUseCase.deleteData(dataEdit.value.Id)

          if (response.error) {
            $toast.add({
              severity: 'error', detail: 'Gagal menghapus stiker, silahkan coba kembali', group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successDeleteData, group: 'bc', life: 3000
            })
            emit('reloadData')
            closeSidebar()
          }
        }
      })
    }

    onMounted(() => {
      getDataForm()
    })
    return {
      title,
      dataEdit,
      route,
      submitted,
      dataForm,
      v$,
      submitData,
      deleteData,
      closeSidebar
    }
  }
}
